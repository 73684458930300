<template>
	<div class="mywzNews">
		<div class="mywzNewsItem">
			<div class="textPart flex">
				<div class="imgPart">
					<img
						class="mywzNews_pic pointer"
						:src="item.pic"
						:key="item.pic"
						@click="
							$router.push({
								path: '/gw_detail',
								query: { id: item.id },
							})
						"
					/>
				</div>
				<div class="mainContent">
					<div
						class="mainContent_title pointer"
						@click="
							$router.push({
								path: '/gw_detail',
								query: { id: item.id },
							})
						"
						>{{ item.title }}</div
					>
					<div class="mainContent_timePart flex justify_content_sb">
						<div
							class="mainContent_time"
							:class="{
								mainContent_time_none:
									item.examine_type == 3 || item.examine_type == 4,
							}"
						>
							{{ item.release_time }}
						</div>
						<div class="mainContent_edit flex">
							<div
								class="pointer"
								v-if="item.examine_type == 3 || item.examine_type == 4"
								@click="editItem()"
								>编辑</div
							>
							<div class="pointer" @click="delItem()">删除</div>
						</div>
					</div>
				</div>
			</div>

			<div class="mainContent_status">
				<img
					src="https://zy.metaera.media/assets/images/my/mywz_examineing.png"
					v-if="item.examine_type == 2"
				/>
				<img
					src="https://zy.metaera.media/assets/images/my/mywz_nopass.png"
					v-if="item.examine_type == 3"
				/>
				<span v-if="item.examine_type == 1" style="color: #2fcc71">已上线</span>
				<span v-if="item.examine_type == 2" style="color: #0056ff">审核中</span>
				<span v-if="item.examine_type == 3" style="color: #e74b3a">未通过</span>
				<span v-if="item.examine_type == 4" style="color: #999999">草稿</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["item"],
	methods: {
		editItem() {
			this.$emit("editItem", this.item.id);
		},
		delItem() {
			this.$confirm("此操作将删除该条专栏, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$emit("delItem", this.item.id);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
	},
};
</script>

<style scoped>
.mywzNewsItem {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #f7f7f7;
	padding-bottom: 33px;
	padding-top: 33px;
}

.mywzNews_pic {
	width: 274px;
	height: 170px;
	object-fit: cover;
	border-radius: 6px;
}
.mainContent {
	position: relative;
	margin-left: 40px;
}
.mainContent_title {
	font-size: 20px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	line-height: 24px;
	color: #000000;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	/* position: absolute; */
	top: 0px;
}
.mainContent_title:hover {
	color: #0056ff;
}
.mainContent_timePart {
	position: absolute;
	bottom: 0px;
}
.mainContent_edit div {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
	width: 90px;
}
.mainContent_time {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #8e8e8e;
	width: 480px;
}
.mainContent_time_none {
	width: 400px;
}
.mainContent_status {
	position: relative;
	right: 0px;
	height: 60px;
}
.mainContent_status img {
	width: 15px;
	height: 15px;
}
.mainContent_status span {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	margin-left: 8px;
}
</style>
