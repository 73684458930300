import request from "@/utils/request";

// 专题1111111
export function specialList(obj) {
	return request({
		url: "/selectSpecial",
		method: "get",
		params: obj,
	});
}
//   类别1111111
export function getCategoryList(obj) {
	return request({
		url: "/categoryLists",
		method: "get",
		params: obj,
	});
}
//   添加资讯1111111
export function addInformation(obj) {
	return request({
		url: "/user/addInformation",
		method: "post",
		data: obj,
	});
}
export function delInformation(obj) {
	return request({
		url: "/user/delInformation",
		method: "post",
		data: obj,
	});
}
//   标签列表1111111
export function getLabelList(obj) {
	return request({
		url: "/selectLabel",
		method: "get",
		params: obj,
	});
}
